import React from 'react';

// styles
import styles from './vstack.module.scss';

const VStack = React.forwardRef(({ children, className = '', ...props }, ref) => {
    return (
        <div className={[styles.vstack, className].join(' ')} ref={ref} {...props}>
            {children}
        </div>
    );
});

export default VStack;
