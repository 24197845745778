import React, { useState, useEffect } from "react";
import dynamic from 'next/dynamic';

import Image from 'next/image';

// styles
import styles from 'styles/landing.module.scss';

// components
import Button from "avena-components/button";
import Col from 'avena-components/col';
import Row from 'avena-components/row';
import Spacer from 'avena-components/spacer';
import VStack from "components/vstack/vstack";
import HStack from "components/hstack/hstack";
const ContactUsForm = dynamic(() => import('components/contact-us-form'), { ssr: false, loading: () => <p>Loading...</p> })
import { useRouter } from "next/router";

// images
import talent_hub_image from '/public/talenthub.webp';
import intime_image from '/public/intime.jpg'
import retrace_image from '/public/retrace.jpg'
import raxar_image from '/public/raxar.jpg'
import arabic_image from '/public/arabic.jpg'
import duzey_ligi_image from '/public/duzey_ligi.jpg'
import raxar_app_image from '/public/raxar-app.jpg'
import talent_hub_image_mobile from '/public/talenthub-mobile.webp';
import intime_image_mobile from '/public/intime-mobile.webp'
import retrace_image_mobile from '/public/retrace-mobile.webp'
import raxar_image_mobile from '/public/raxar-mobile.webp'
import arabic_image_mobile from '/public/arabic-mobile.webp'
import duzey_ligi_image_mobile from '/public/duzey-ligi-mobile.webp'
import raxar_app_image_mobile from '/public/raxar-app-mobile.webp'
import talent_hub_banner from '/public/banner-background-1.webp';
import intime_banner from '/public/banner-background-3.webp';

const howWeDoItSteps = [
    {
        number: 1,
        title: "Understanding Your Business",
        description: "Tell us your needs, desired functionality, the business methods used, and we can build you the software solutions. Our software is scalable and flexible enough for you to easily adapt down the road."
    },
    {
        number: 2,
        title: "In-depth Discussion",
        description: "We begin every relationship with an in-depth discussion about the short and long-term desires for your project."
    },
    {
        number: 3,
        title: "Scrum",
        description: "At AVENA, we believe in the Scrum framework where everyone works together innovatively and productively to solve complex problems. Each project will have a scrum master who is the connection between the development team and the client, making sure that the whole process is transparent and smooth."
    },
    {
        number: 4,
        title: "Requirement Analysis",
        description: "Our engineers will review any hidden requirements and your existing codebase if you have one. We need to know your goals and understand your existing software to ensure the success of the project. This also makes sure the development stage can run smoothly."
    },
    {
        number: 5,
        title: "Create an Effective Action Plan",
        description: "By the end of this phase, AVENA works together with you to make a plan of development that accords with your goals, timeline, and operating budget."
    },
    {
        number: 6,
        title: "IPR Protection",
        description: "AVENA guarantees that all your information, data, business processes are secured, protected, and well-guarded since protecting intellectual property rights becomes a must for any business."
    }
];

const howToGetStartedSteps = [
    {
        number: 1,
        title: "Tell Us About Your Project",
        description: "Our senior technology consultants will help you define your goals and objectives, find out if we're a good fit for your project, and discuss the budget and timeline."
    },
    {
        number: 2,
        title: "Get a Quote",
        description: "Once you know what you want, we'll provide a quote to complete your project. We're confident in our ability to meet deadlines while providing the best possible value for your project and budget."
    },
    {
        number: 3,
        title: "Meet Your Development Team",
        description: "Once you know what you want, we'll provide a quote to complete your project. We're confident in our ability to meet deadlines while providing the best possible value for your project and budget."
    },
    {
        number: 4,
        title: "Start the Project",
        description: "We keep the lines of communication open, so we will provide day-to-day updates on your project and publish a final report."
    },
    {
        number: 5,
        title: "Successful Delivery",
        description: "You've hit the finish line! We will be there every step of the way to help you implement your customized software and provide support after launch."
    }
];

const service_options = [
    {
        name: {
            part1: "Custom Enterprise Application",
            part2: "Development",
        },
        text: <VStack style={{ gap: '10px' }}>
            <div>AVENA has extensive experience developing custom enterprise applications for companies across industries. We develop tailored custom software solutions to fit your specific requirements, so you'll get the best software possible.</div>
        </VStack>,
        mobileVisible: true,
        image: {
            src: '/options_1.webp',
            width: 272,
            height: 298,
            mobileSrc: '/service_image_mobile_1.webp'
        },
        icon: {
            src: '/service_icon_1.webp',
        }
    },
    {
        name: {
            part1: "Software Product",
            part2: "Development",
        },
        text: <VStack style={{ gap: '10px' }}>
            <div>Let's begin a conversation about your new ideas or ongoing projects that need the fresh and innovative touch of our expert developers. This ranges from simple custom software application development to complicated, long-term contracts.</div>
        </VStack>,
        mobileVisible: false,
        image: {
            src: '/options_2.webp',
            width: 272,
            height: 298,
            mobileSrc: '/service_image_mobile_2.webp'
        },
        icon: {
            src: '/service_icon_2.webp',
        }
    },
    {
        name: {
            part1: "Custom Web Application",
            part2: "Development",
        },
        text: <VStack style={{ gap: '10px' }}>
            <div>AVENA provides custom web application development services to build cost-effective web applications that are secure, scalable, accessible, and maintainable.</div>
        </VStack>,
        mobileVisible: false,
        image: {
            src: '/options_3.webp',
            width: 272,
            height: 298,
            mobileSrc: '/service_image_mobile_3.webp'
        },
        icon: {
            src: '/service_icon_3.webp',
        }
    },
    {
        name: {
            part1: "Custom Mobile App",
            part2: "Development",
        },
        text: <VStack style={{ gap: '10px' }}>
            <div>We create high-performance apps for iOS and Android. Our professional custom mobile app developers have the experience to develop native, hybrid, and cross-platform apps.</div>
        </VStack>,
        mobileVisible: false,
        image: {
            src: '/options_4.webp',
            width: 272,
            height: 298,
            mobileSrc: '/service_image_mobile_4.webp'
        },
        icon: {
            src: '/service_icon_4.webp',
        }
    },
    {
        name: {
            part1: "Software Enhancement and",
            part2: "Modernization, Project Recovery",
        },
        text: <VStack style={{ gap: '10px' }}>
            <div>With expertise and constant updates, we will utilize the latest and up-to-date technologies to lengthen the life of your legacy system. We also help you finish your troubled projects before deadlines if your existing provider is not working out. </div>
        </VStack>,
        mobileVisible: false,
        image: {
            src: '/options_5.webp',
            width: 272,
            height: 298,
            mobileSrc: '/service_image_mobile_5.webp'
        },
        icon: {
            src: '/service_icon_5.webp',
        }
    },
];

const ourExpertiseCards = [
    {
        image: {
            src: '/expertise_1.webp',
            alt: 'expertise_1',
            mobileSrc: '/our-expertise-mobile-1.webp',
            width: 486,
            height: 337,
            bgColor: '#E7F8EE'

        },
        title: {
            part1: "Web Application",
            part2: "Development"
        },
        id: 'web-application-development',
        description: {
            part1: "AVENA is a web application development company you can trust with the engineering of impactful, efficient, and easy-to-use corporate and customer-facing websites, web apps, and web portals.",
            part2: "Our product delivery expertise includes Consumer and Enterprise Web Apps, Web Portals, Customer-Facing Apps, Online Stores, Healthcare & Facility Management Apps."
        }
    },
    {
        image: {
            src: '/expertise_2.webp',
            alt: 'expertise_2',
            mobileSrc: '/our-expertise-mobile-2.webp',
            width: 418,
            height: 356,
            bgColor: '#FDF1F1'
        },
        title: {
            part1: "Mobile Application",
            part2: "Development"
        },
        id: 'mobile-application-development',
        description: {
            part1: "Mobile app development services are aimed at building iOS & Android applications that effectively complement or substitute web solutions. AVENA ensures app success by delivering striking UI, secure app code, and resilient back ends. We welcome possibilities to assist in specific development stages as well as to provide full-cycle mobile app implementation. ",
            part2: "Our mobile app development expertise includes Mobile Consulting, Mobile UI/UX, Mobile App Frontend Development, Backend Development, App Integration, App ",
        }
    },
    {
        image: {
            src: '/expertise_3.webp',
            alt: 'expertise_3',
            mobileSrc: '/our-expertise-mobile-3.webp',
            width: 663,
            height: 474,
            bgColor: '#EBF6FD'
        },
        title: {
            part1: "Artificial Intelligence (AI)",
            part2: "Development"
        },
        id: 'artifical-intelligence',
        description: {
            part1: "We can develop custom AI applications for your business. Our team has extensive experience with AI software development and technologies, including machine learning, natural language processing, and computer vision. We can also help you integrate AI into your existing systems and processes.",
            part2: "Our AI expertise includes Machine Learning, Optimization, Natural Language Processing, Classification & Clustering, Computer Vision, Predictive Modeling."
        }
    },
    {
        image: {
            src: '/expertise_4.webp',
            alt: 'expertise_4',
            mobileSrc: '/our-expertise-mobile-4.webp',
            width: 498,
            height: 342,
            bgColor: '#E9EAFA'
        },
        title: {
            part1: "User Experience",
            part2: "and Design"
        },
        id: 'user-experience-and-design',
        description: {
            part1: "UI/UX design is the process of maximizing user satisfaction by improving the usability and accessibility of a web or mobile application. A well-designed product not only looks great and stylish but has a clear UI, intuitive navigation, and easily accessible features which provide a smooth and efficient user experience. We can help with the design process from beginning to end whether you need a full-scale design or just some tweaks",
            part2: "Our UI/UX Design expertise includes UI design for web applications, UI design for mobile apps, UI Wireframes, UX research, User flow, Wireframes & prototypes."
        }
    }
];

const portfolios = [
    {
        header: 'TalentHub is a global talent search tool for IT recruitment that helps businesses reduce recruitment costs and find the perfect expertise.',
        body: 'It offers an AI-powered talent search engine that sources, filters, and connects highly-qualified engineers, and helps recruiters generate a talent pool efficiently. TalentHub also helps recruiters find the right contact details for candidates and create lists of ideal candidates that can be exported easily.',
        technologies: ['React', 'Node.js', 'MongoDB', 'UI Development', 'UI Design', 'Javascript', 'HTML', 'CSS', 'ExpressJS', 'Next.js', 'AWS Lambda', 'AWS', 'Web Front-End Development', 'Web Back-End Development'],
        img: talent_hub_image,
        img_mobile: talent_hub_image_mobile,
        img_alt: 'talenthub_sample',
        icon_alt: 'talenthub_logo',
        icon: '/talent-hub-colored.webp',
        width: 82,
        height: 74,
        url: 'https://talenthub.avena.co/'
    },
    {
        header: 'Easy to use employee time tracking software with built-in invoicing and powerful analytics.',
        body: 'This app simplifies project management by tracking time, costs, and invoices. Its user-friendly interface allows for easy logging of hours and costs, viewing of employee hours, and creating professional-looking invoices. You can also track unpaid invoices for timely payments.',
        technologies: ['Node.js', 'MongoDB', 'React', 'Web Front-end Development', 'Web Back-end Development', 'UI Development', 'Javascript', 'HTML', 'CSS', 'ExpressJS'],
        img: intime_image,
        img_mobile: intime_image_mobile,
        img_alt: 'intime_sample',
        icon_alt: 'intime_logo',
        icon: '/intime.svg',
        width: 155,
        height: 40,
        url: 'https://intimeapp.io/'
    },
    {
        header: 'How Retrace filled the oral care system gap with an AI solution',
        body: 'Retrace is an AI and cloud-based implementation that offers oral health care providers a precise payment, complete billing and office solution that connects entire practices.',
        technologies: ['Node.js', 'React.js', 'MongoDB', 'AWS S3', 'AWS Lambda', 'Flask', 'Kubernetes', 'Amazon EKS', 'Python', 'Data Science', 'Deep Learning', 'Web Development', 'Javascript', 'Windows Development', '.NET Framework', 'C# ', 'QA Testing', 'Cucumber', 'Jenkins', 'Java'],
        img: retrace_image,
        img_mobile: retrace_image_mobile,
        img_alt: 'retrace_sample',
        icon_alt: 'retrace_logo',
        icon: '/retrace.svg',
        width: 133,
        height: 26
    },
    {
        header: 'How Raxar built an organization management solution for multi-functioned companies',
        body: 'Raxar provides a multi-content management tool to help organizations operate, plan, design, assign and share different components of an organization.',
        technologies: ['QA Testing', 'React', 'PHP', 'HTML', 'Laravel', 'Swift', 'Objective-C', 'Web Front-end Development', 'Web Back-end Development', 'Mobile App Development', 'Node.js', 'Javascript', 'HTML', 'CSS', 'AWS Lambda'],
        img: raxar_image,
        img_mobile: raxar_image_mobile,
        img_alt: 'raxar_sample',
        icon_alt: 'raxar_logo',
        icon: '/raxar.svg',
        width: 143,
        height: 46
    },
    {
        header: 'The Arabic Quran project is actually an application for learning the Arabic language through the Quran, rather than just a Quran application.',
        body: 'The application is designed to help users improve their Arabic language skills by reading and understanding the Quran, in addition to providing the features of a typical Quran application, such as searching for specific verses and marking favorite passages.',
        technologies: ['React Native', 'Mobile Development', 'Front-end Development', 'Javascript', 'Firebase', 'Android App ', 'iOS App'],
        img: arabic_image,
        img_mobile: arabic_image_mobile,
        img_alt: 'arabic_sample',
        icon_alt: 'arabic_logo',
        icon: '/arabic.svg',
        width: 81,
        height: 81
    },
    {
        header: 'Boost Your Business with Duzeyligi: The User-Friendly App for Setting Sales Goals and Tracking Performance',
        body: 'Duzeyligi is a mobile app that helps market sellers track sales, compare performance with others, and earn rewards. It lets sellers set goals, track progress, and compete for cash prizes. The app is easy to use, compatible with iOS and Android, and suitable for sellers of all sizes and industries.',
        technologies: ['React Native', 'Firebase', 'Mobile Development', 'Javascript', 'Node.js', 'Android App ', 'iOS App'],
        img: duzey_ligi_image,
        img_mobile: duzey_ligi_image_mobile,
        img_alt: 'duzey_ligi_sample',
        icon_alt: 'duzey_ligi_logo',
        icon: '/duzey_ligi.svg',
        width: 143,
        height: 143
    },
    {
        key: 'raxar-app',
        header: 'Raxar helps enterprise organizations’ operations teams eliminate paper and Excel-based workflows.',
        body: "Duzeyligi is a mobile app that helps market sellers track sales, compare performance with others, and earn rewards. It lets sellers set goals, track progress, and compete for cash prizes. The app is easy to use, compatible with iOS and Android, and suitable for sellers of all sizes and industries.",
        technologies: ['QA Testing', 'PHP', 'Laravel', 'Swift', 'Objective-C', 'Mobile App Development', 'Web Back-end Development', 'Node.js', 'Javascript', 'AWS Lambda'],
        img: raxar_app_image,
        img_mobile: raxar_app_image_mobile,
        img_alt: 'raxar_app_sample',
        icon_alt: 'raxar_app_logo',
        icon: '/raxar-app.svg',
        width: 81,
        height: 81
    },
]

const products = [
    {
        header: 'Dedicated teams',
        body: 'You are given a Product Manager, Tech Lead, Developers and a UX Specialist.'
    },
    {
        header: 'Daily communication',
        body: 'Our Delivery system ensures proactive updates, which means you are never in the dark.'
    },
    {
        header: 'Rapid executions',
        body: 'Our weekly sprints help in prioritizing tasks and focus on building the MVP fast.'
    },
    {
        header: 'Predictable ROI',
        body: 'Our process management gives you predictable measures to plan a solid product launch.'
    },
]

const Index = () => {
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (window.innerWidth < 768) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
            window.addEventListener('resize', () => {
                if (window.innerWidth < 768) {
                    setIsMobile(true);
                } else {
                    setIsMobile(false);
                }
            });
        }
    }, []);

    const router = useRouter();

    return (
        <VStack className={styles.landing}>
            <Row className={styles.section_1}>
                <Col xs={24} sm={24} md={24} lg={10} xl={10} className={styles.col}>
                    <div className={styles.background}>
                        {isMobile ?
                            <Image width={390} height={328} priority src='/landing-background-mobile.webp' className={styles.background_image} alt="landing_background_mobile" /> :
                            <Image width={828} height={528} priority src='/landing-background.webp' className={styles.background_image} alt="landing_background" />
                        }
                    </div>
                    <VStack className={styles.text_box}>
                        <div className={styles.header}>Unleash the Power of Digital Transformation with AVENA</div>
                        <div className={styles.body}>Contact Us Today to Transform Your Business with Custom Software Solutions</div>
                    </VStack>
                </Col>
            </Row>
            <Row className={styles.banner_section}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} className={styles.first_column}>
                    <Image priority src={talent_hub_banner} className={styles.background} fill alt="banner_background" />
                    <div className={styles.background2} />
                    <VStack className={styles.flex_container}>
                        <Image width={95} height={86} src='/talent-hub-white.webp' alt="talent_hub_white" className={styles.icon} />
                        <div className={styles.header}>Find your next great hire.</div>
                    </VStack>
                    <div className={styles.body}>Search what you are looking for and get the best-macthes.</div>
                    <Button onClick={() => router.push('https://talenthub.avena.co/')} className={styles.button}>Visit TalentHub &rarr;</Button>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} className={styles.second_column}>
                    <Image priority alt="banner_background_3" fill src={intime_banner} className={styles.background} />
                    <div className={styles.background2} />
                    <VStack className={styles.flex_container}>
                        <Image width={148} height={86} alt="intime_logo" src='/intime.webp' className={styles.icon} />
                        <div className={styles.header}>Get Paid Faster</div>
                    </VStack>
                    <div className={styles.body}>Easy to use employee time tracking software with built-in invoicing and powerful analytics.</div>
                    <Button onClick={() => router.push('https://intimeapp.io/')} className={styles.button}>Visit intime &rarr;</Button>
                </Col>
            </Row>
            <Row className={styles.section_2}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} className={styles.first_column}>
                    <VStack >
                        <div className={styles.header}>About Us</div>
                        <div className={styles.body}>AVENA is a custom software development company that delivers customized solutions for businesses. Our experienced team of senior consultants assists in digital transformation, providing progressive and innovative technology solutions that can change lives and improve businesses. </div>
                    </VStack>
                    <Button type='secondary' className={styles.button} onClick={() => router.push('/about-us')}>Learn more</Button>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} className={styles.second_column}>
                    <div className={styles.header}>Principles</div>
                    <HStack className={styles.row}>
                        <div className={styles.col}>
                            <div className={styles.divider} />
                            <HStack className={styles.content_container}>
                                <Image width={46} height={46} src='/developer.webp' alt="developer_icon" />
                                <div>Learning & Development</div>
                            </HStack>
                            <HStack className={styles.content_container}>
                                <Image width={46} height={46} src='/direction.webp' alt="direction_icon" />
                                <div>Mindful Personal Guidance</div>
                            </HStack>
                            <HStack className={styles.content_container}>
                                <Image width={46} height={46} src='/thumb.webp' alt="thumb_icon" />
                                <div>Constructive Feedback</div>
                            </HStack>
                            <HStack className={styles.content_container}>
                                <Image width={46} height={46} src='/flag.webp' alt="flag_icon" />
                                <div>Inclusive & Open-Culture</div>
                            </HStack>
                        </div>
                        <div className={styles.col}>
                            {!isMobile && <div className={styles.divider} />}
                            <HStack className={styles.content_container}>
                                <Image width={46} height={46} src='/guarantee.webp' alt="guarantee_icon" />
                                <div>Innovation & Quality First</div>
                            </HStack>
                            <HStack className={styles.content_container}>
                                <Image width={46} height={46} src='/rocket.webp' alt="rocket_icon" />
                                <div>Proactive Implementation</div>
                            </HStack>
                            <HStack className={styles.content_container}>
                                <Image width={46} height={46} src='/handshake.svg' alt="handshake_icon" />
                                <div>Best & Global Opportunities</div>
                            </HStack>
                            <HStack className={styles.content_container}>
                                <Image width={46} height={46} src='/technology.webp' alt="technology_icon" />
                                <div>Work with Latest Technology</div>
                            </HStack>
                        </div>
                    </HStack>
                </Col>
            </Row>
            <Row className={styles.section_3}>
                <Col xs={24} sm={24} md={10} lg={15} xl={15} className={styles.first_column}>
                    <div className={styles.header}>Our Methogology</div>
                    <div className={styles.body}>
                        At AVENA, we agile your products to success. After a definite period of time, through the agile way, you don’t just get one, but multiple functionalities of your product. To achieve this, Developers, Designers, and Testers work simultaneously and co-dependently.
                    </div>
                    <div className={styles.body}>
                        We start by breaking the app development cycle into different tasks. These tasks are divided again into subtasks, each of which is performed simultaneously after being assigned to our teams.
                    </div>
                    <Spacer />
                    <Button type='secondary' className={styles.button} onClick={() => router.push('/our-methodology')}>Learn More</Button>
                </Col>
                <Col xs={24} sm={24} md={14} lg={9} xl={9} className={styles.second_column}>
                    <Image width={480} height={440} src='/handshake-2.webp' className={styles.image} alt="handshake_2_image" />
                </Col>
            </Row>
            <VStack className={styles.section_4}>
                <VStack>
                    <div className={styles.header}>
                        At Avena, we don’t just talk about great products. We make them with our clients.
                    </div>
                </VStack>
                <Row className={styles.row}>
                    {products.map((product, ind) => (
                        <Col xs={24} sm={12} md={12} lg={12} xl={6} className={styles.card} key={ind}>
                            <div className={styles.circle}><Image width={15} height={12} src='/checkmark.svg' alt="checkmark_icon" /></div>
                            <VStack className={styles.text_container}>
                                <div className={styles.sub_header}>{product.header}</div>
                                <div className={styles.body}>{product.body}</div>
                            </VStack>
                        </Col>
                    ))}
                </Row>
                {!isMobile && <div className={styles.divider} />}
            </VStack>
            <div id='portfolio' className={styles.portfolio}>
                <div className={styles.carousel}>
                    <div className={styles.portfolio_header}>Portfolio</div>
                    {
                        portfolios.map((el, ind) => (
                            <Row className={styles.item}>
                                <Col xs={24} sm={24} md={24} lg={8} xl={8} >
                                    {isMobile && <div className={styles.image_container}><Image alt={el.img_alt} fill src={el.img_mobile} /></div>}
                                    <HStack className={styles.icon_container}>
                                        <Image width={el.width} height={el.height} src={el.icon} alt={el.icon_alt} className={styles.icon} />
                                        {
                                            el.key == 'raxar-app' &&
                                            <div className={styles.icon_subtext}>Raxar <br /> App</div>
                                        }
                                    </HStack>
                                    <HStack>
                                        <Image className={styles.arrow_icon} width={18} height={18} alt="caret_svg" src='/caret.svg' />
                                        <VStack className={styles.vstack}>
                                            <div className={styles.header}>{el.header}</div>
                                            <div className={styles.body}>{el.body}</div>
                                            {el.url && isMobile && <Button type={'secondary'} className={styles.visit} onClick={() => router.push(el.url)}>Visit site &rarr;</Button>}
                                            <div className={styles.hr} />
                                            <div className={styles.technology_text}>Technologies</div>
                                            <HStack className={styles.technology_container}>
                                                {el.technologies.map((el, ind) => <div key={ind} className={styles.technology}>
                                                    <div className={styles.technology_font}>
                                                        {el}
                                                    </div>
                                                </div>)}
                                            </HStack>
                                            {el.url && !isMobile && <Button type={'secondary'} className={styles.visit} onClick={() => router.push(el.url)}>Visit site &rarr;</Button>}
                                        </VStack>
                                    </HStack>
                                </Col>
                                {!isMobile && <Col xs={24} sm={24} md={24} lg={16} xl={16} className={styles.image_container}>
                                    <Image src={el.img} alt={el.img_alt} />
                                </Col>}
                            </Row>
                        ))
                    }
                </div>
            </div>
            <Row className={styles.section_5}>
                <Col className={styles.sectionHeader} style={{ gap: '0px' }}>
                    <div className={styles.divider} />
                    <div className={styles.title} id="services">Services</div>
                    <div className={styles.description}>We provide high-quality, cost-effective, reliable, full-cycle bespoke software development that matches your specific needs, budget, and timeframe. Increase your competitive advantage with a custom solution.</div>
                </Col>
                <Col className={styles.textContainer} style={{ gap: '0px' }}>
                    <Row>
                        <Col className={styles.leftColumn} xs={24} sm={24} md={24} lg={12} xl={12}>
                            Custom software development is the process of designing, implementing, testing, deploying software that is custom built to fit your organization’s requirements rather than purchased off-the-shelf software.
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            We can help you create new custom software solutions, improve existing solutions, modernize legacy systems, and integrate new systems with existing ones.
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className={styles.section_6} style={{ gap: '0px' }}>
                <Col className={styles.card_container}>
                    <Row className={styles.row}>
                        {service_options.map((el, ind) => (
                            <Col className={styles.content} xs={24} sm={24} md={24} lg={24} xl={12}>
                                <HStack key={ind} className={styles.hstack}>
                                    {isMobile ?
                                        <Image
                                            key={ind}
                                            src={el.image.mobileSrc}
                                            alt="Find job"
                                            width={322}
                                            height={297}
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                            }}
                                        /> :
                                        <Image
                                            key={ind}
                                            src={el.image}
                                            alt="Find job"
                                            style={{
                                                maxWidth: '100%',
                                                height: 'auto',
                                            }}
                                        />
                                    }
                                    <VStack style={{ gap: '20px' }}>
                                        <Image
                                            key={ind}
                                            src={el.icon.src}
                                            alt="service_icon"
                                            width={78}
                                            height={78}
                                            style={{
                                                maxWidth: '100%',
                                                height: 'auto',
                                            }}
                                        />
                                        <div className={styles.option_header}>{el.name.part1}<br />{el.name.part2} </div>
                                        <div className={styles.body_text}>{el.text}</div>
                                    </VStack>
                                    <div className={styles.divider} />
                                </HStack>
                            </Col>
                        ))}
                        {!isMobile &&
                            <Col xl={12} className={styles.get_in_touch_with_us}>
                                <div className={styles.header} onClick={() => router.push('/#contact-us')}>
                                    Get in Touch
                                    <br />
                                    With Us
                                    <br />
                                    <Image src='/arrow.webp' alt='arrow' width={136} height={20} />
                                </div>
                            </Col>
                        }
                    </Row>
                </Col>
            </Row>
            <Row className={styles.section_7} style={{ gap: '0px' }}>
                <Col className={styles.divider} xs={24} sm={24} md={24} lg={24} xl={24}></Col>
                <Col className={styles.sectionHeader} style={{ gap: '0px' }}>
                    <div id="our-expertise">Our Expertise</div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row className={styles.cardContainer}>
                        {
                            ourExpertiseCards.map((expertiseCard, ind) => (
                                <Col className={styles.card} xs={24} sm={24} md={12} lg={12} xl={12} id={expertiseCard.id} key={ind}>
                                    <div className={styles.cardImage} style={{ backgroundColor: expertiseCard.image.bgColor }}>
                                        {isMobile ?
                                            <Image width={270} height={185} alt={expertiseCard.image.alt} src={expertiseCard.image.mobileSrc} /> :
                                            <Image width={457} height={314} src={expertiseCard.image.src} alt={expertiseCard.image.alt} />
                                        }
                                    </div>
                                    <div className={styles.cardBody}>
                                        <div className={styles.cardTitle} >
                                            {expertiseCard.title.part1} <br /> {expertiseCard.title.part2}
                                        </div>
                                        <div className={styles.cardDescription}>
                                            {expertiseCard.description.part1} <br /><br /> {expertiseCard.description.part2}
                                        </div>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                </Col>
            </Row>
            <Row className={styles.section_8} style={{ gap: '0px' }}>
                <Col className={styles.sectionHeader} style={{ gap: '0px' }}>
                    <div id="how-to-get-started">How To Get Started</div>
                </Col>

                {/* This col is only for the border */}
                <Col className={styles.divider} xs={24} sm={24} md={24} lg={24} xl={24} />

                {
                    howToGetStartedSteps.map((step, ind) => (
                        <Col className={styles.rowContainer} xs={24} sm={24} md={24} lg={24} xl={24} key={ind}>
                            <Row className={styles.row}>
                                <Col className={styles.numberText} xs={1} sm={1} md={1} lg={1} xl={1}>
                                    {step.number}
                                </Col>
                                <Col className={styles.contentContainer} xs={23} sm={23} md={23} lg={23} xl={23}>
                                    <Row>
                                        <Col className={styles.titleText} xs={10} sm={10} md={10} lg={10} xl={10}>
                                            {step.title}
                                        </Col>
                                        <Col className={styles.descriptionText} xs={14} sm={14} md={14} lg={14} xl={14}>
                                            {step.description}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    ))
                }
            </Row>
            <Row className={styles.section_9} style={{ gap: '0px' }}>
                <Col className={styles.sectionHeader}>
                    <div id="how-we-do-it">How We Do It</div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row className={styles.cardContainer}>
                        {
                            howWeDoItSteps.map((step, index) => (
                                <Col className={styles.card} xs={24} sm={24} md={12} lg={8} xl={8} key={index}>
                                    <div className={styles.circledNumber}>
                                        <div className={styles.text}>
                                            {step.number}
                                        </div>
                                    </div>

                                    <div className={styles.cardHeader}>{step.title}</div>
                                    <div className={styles.cardDescription}>{step.description}</div>
                                </Col>
                            ))
                        }
                    </Row>
                </Col>
            </Row>
            <div className={styles.section_10}>
                <ContactUsForm id="contact-us" />
            </div>
        </VStack>
    );
};

export default Index;
