import React from 'react';

// styles
import styles from './hstack.module.scss';

export default function HStack({ children, className = '', ...props }) {
    return (
        <div className={[styles.hstack, className].join(' ')} {...props}>
            {children}
        </div>
    );
}
